<template>
  <div>
      <v-flex xs12 sm8 offset-sm-2 class="my-4 px-8">
      <v-card class="white--text mb-1 mt-8 " flat :tile="true">
        <v-btn
          fab
          small
          color="primary accent-2"
          bottom
          right
          absolute
          @click="goHome"
        >
          <v-icon>mdi-home</v-icon>
        </v-btn>

        <v-container fluid grid-list-lg>
          <v-layout row>
            <v-flex xs7>
              <div>
                <div class="display-1 black--text text--darken-1">Terms and Conditions</div>
<!--                <div class="grey&#45;&#45;text">-->
<!--                    Your one stop shop for all poultry products-->
<!--                </div>-->
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>

      <v-card color="white mb-15" flat class="white--text" height="100%">

        <!--comes here-->
        <v-card flat>
          <v-card-text>
            <!--//Tab contents-->
            <v-container fluid grid-list-lg class="">
              <v-layout row>
                <v-flex xs12>
                  <div class="">
                    <div class="headline primary--text">Introduction</div>
                    <blockquote class="grey--text text--darken-1">
                      Welcome to akokomarket.com (the "Website"). 
                      These terms and conditions (the "Terms") govern your use of the Website. 
                      By accessing or using the Website, you agree to be bound by these Terms. 
                      If you do not agree with any part of these Terms, please do not use the Website.

                        <v-divider class="my-4"></v-divider>

                      <div class="headline primary--text">Definitions</div>

                      "Website" refers to akokomarket.com.<br/>
                      "User" refers to any individualor institution who accesses or uses the Website.<br/>
                      "Customer" refers to a User who makes a purchase through the Website.<br/>
                      "Products" refers to the goods or services available for purchase on the Website.

                        <v-divider class="my-4"></v-divider>

                      <div class="headline primary--text">User Obligations</div>

                      By using the Website, you agree to provide accurate and up-to-date information.
                      You shall not engage in any unlawful, fraudulent, or unauthorized activities on the Website.
                      You must be of legal age to make purchases on the Website.

                        <v-divider class="my-4"></v-divider>

                      <div class="headline primary--text">Ordering and Purchasing</div>

                      To place an order and purchase Products, you must follow the process outlined on the Website.
                      Quantities may be limited, and eligibility requirements may apply for certain Products.
                      Payment methods accepted and applicable fees will be displayed during checkout.
                        <v-divider class="my-4"></v-divider>
                      <div class="headline primary--text">Delivery</div>

                      We will make reasonable efforts to deliver Products within the specified time frames.
                      Delivery if any, will be communicated to you during or after checkout.
                      We are not responsible for lost or damaged during delivery. 
                      <v-divider class="my-4"></v-divider>

                      <div class="headline primary--text">Returns and Refunds</div>

                      Our <a href="/returns_and_refunds">Returns and Refunds Policy</a> governs the return and refund process for Products purchased through the Website.
                      You may be eligible for a return or refund subject to the terms and conditions outlined in our policy.
                      <v-divider class="my-4"></v-divider>

                      <div class="headline primary--text">Intellectual Property</div>

                      All intellectual property rights, including trademarks, copyrights, and content on the Website, are owned by Agro Innova LLC.
                      You may not use, reproduce, or distribute any content without our prior written consent.
                      <v-divider class="my-4"></v-divider>

                      <div class="headline primary--text">Privacy and Data Protection</div>

                      Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and protect your personal information.
                      <v-divider class="my-4"></v-divider>

                      <div class="headline primary--text">Dispute Resolution</div>

                      These Terms are governed by the laws of Ghana. Any disputes shall be resolved in the appropriate courts of jurisdiction.
                      Mediation or arbitration may be required before initiating legal action, if applicable.

                      <v-divider class="my-4"></v-divider>

                      <div class="headline primary--text">Limitations of Liability</div>

                      We do not assume liability for any damages or losses incurred by Users or Customers.
                      We may limit our liability for certain types of damages as permitted by law.
                      <v-divider class="my-4"></v-divider>

                      <div class="headline primary--text">Amendments</div>

                      These Terms may be updated or modified at any time without prior notice.
                      We will notify Users of any changes through the Website.
                      <v-divider class="my-4"></v-divider>
                      
                      <div class="headline primary--text">Miscellaneous</div>

                      If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
                      These Terms constitute the entire agreement between you and Agro Innova LLC regarding the use of the Website.
                    </blockquote>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
export default {
  name: "terms",

  data: () => ({
    items: [
      { txt: "We are Akokomarket", icon: "mdi-car-sports", set: true },
      { txt: "Our Management Team", icon: "mdi-account", set: false }
    ],
    text: "",
    gold_mgt: true,
    henry: false,
    felix: false,
    violet: false,
    enyo: false
  }),

  methods: {
    goHome() {
      this.$router.push("/");
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
      alert(this.currentItem);
    }
  },
  created() {},
  components: {}
};
</script>

<style scoped></style>
